<template>
    <!-- 相机租赁-详情页-信息部分组件 -->
    <div class="camera_detail_info">
        <el-row :gutter="20">
            <el-col :lg="11" :md="12" ref="imgList">
                <!-- 放大镜图片列表 -->
                <img-list
                    :md-img="mdImg"
                    :img-list="imgs"
                    @change-md="handleMdChange">
                </img-list>
            </el-col>
            <el-col :lg="13" :md="12">
                <!-- 文字部分 -->
                <div class="text_area_box" 
                    v-if="info">
                    <!-- 拥有者行 -->
                    <div class="clothes-owner">
                        <!-- 头像 -->
                        <div class="clothes-owner-avatar">
                          <el-image
                                :src="info.owner_avatar"
                                fit="scale-down"
                                class="clothes-owner-avatar-img">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </div>
                        <!-- 昵称 -->
                        <div class="clothes-owner-name">{{info.owner_name}}</div>
                    </div>
                    <camera-detail-form></camera-detail-form>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

export default {
    data() {
        return {
        }
    },
    computed: mapState({
        // 模态框控制器
        info: state => state.goodsDetail.info,
        mdImg: state => state.goodsDetail.mdImg,
        imgs: state => state.goodsDetail.imgs
    }),
    components:{
        imgList: () => import('./detailImgList'),
        cameraDetailForm: () => import("./cameraDetailForm")
    },
    methods: {
        ...mapMutations([
            "changeMdImg", // 更改中图片api
        ]),
        handleMdChange(md,index) {
            this.changeMdImg({
                md,
                index
            });
        }
    }
}
</script>

<style scoped>
/* 图片 & 文字部分背景颜色 */
.text_area_box{
    padding: 20px;
    height: 100%;
}

.clothes-owner>div{
    display: inline-block;
    vertical-align: middle;
}
/* 头像 */
.clothes-owner-avatar{
    width: 50px;
    height: 50px;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 6px;
}
.clothes-owner-avatar-img{
    width: 100%;
    height: 100%;
}
.clothes-owner-avatar>>>.image-slot{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #f5f7fa;
    color: #909399;
    font-size: 30px;
}
/* 昵称 */
.clothes-owner-name{
    color: #909399;
    font-size: 16px;
    font-weight: bold;
}

</style>
